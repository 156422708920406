exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1M2AT {\n  height: calc(100% - 50px);\n}\n.VZWRS {\n  height: calc(100% - 91px);\n  overflow: auto;\n  padding-top: 0;\n  padding-bottom: 10px;\n}\n.VZWRS ._3ipxd {\n  padding-left: 20px;\n  padding-right: 20px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  height: 40px;\n}\n.VZWRS ._3ipxd .g6uog {\n  line-height: 40px;\n}\n.VZWRS ._3ipxd ._2N01p {\n  border-color: hsl(0, 0%, 80%);\n  border-radius: 4px;\n  border-style: solid;\n  border-width: 1px;\n  color: hsl(0, 0%, 20%);\n}\n._3VqAQ {\n  border-top: 1px solid #CBCBCB;\n  padding: 15px 20px;\n  text-align: center;\n}\n", ""]);

// Exports
exports.locals = {
	"loadingOverlay": "_1M2AT",
	"container": "VZWRS",
	"row": "_3ipxd",
	"label": "g6uog",
	"textarea": "_2N01p",
	"button": "_3VqAQ"
};