import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import Modal from 'Components/modal';
import Button from 'Components/button';
import Tab from 'Components/tab';
import loc from 'Components/languages';
import { getWarehouse, getPmsFilterOptions, getPmsFilters } from 'Redux/actions';
import styles from './ModalAdvancedSearch.less';

class ModalAdvancedSearch extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: 'createdAt',
			orderBy: 'ascending',
			status: 'all',
			docType: 'all',
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 400px)',
				right: 'calc(50% - 400px)',
				maxHeight: '324px',
				padding: 0,
				overflow: 'visible',
			},
		};
		this.tabs = {
			sortBy: [
				'createdAt',
				'updatedOn',
				'docType',
				'remarkContent',
			],
			orderBy: [
				'ascending',
				'descending',
			],
			status: [
				'all',
				'enabled',
				'disabled',
			],
			docType: [
				'all',
				'DP1',
				'SA1',
				'SA3',
			],
		};
		this.parseParam = this.parseParam.bind(this);
		this.onChangeSortByHandler = this.onChangeHandler.bind(this, 'sortBy');
		this.onChangeOrderByHandler = this.onChangeHandler.bind(this, 'orderBy');
		this.onChangeStatusHandler = this.onChangeHandler.bind(this, 'status');
		this.onChangeDocTypeHandler = this.onChangeHandler.bind(this, 'docType');
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}

	componentDidMount() {
		const { param } = this.props;
		this.setState({
			...this.parseParam(param),
		});
	}

	componentDidUpdate(prevProps) {
		const param = this.props.param;
		const prevParam = prevProps.param;
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (
			param && (
				param !== prevParam ||
				(isOpen && !prevIsOpen)
			)
		) {
			this.setState({
				...this.parseParam(param),
			});
		}
	}

	parseParam(param) {
		let ret = {};
		switch (param.get('sort_column')) {
		case 'status':
			ret.sortBy = 'status';
			break;
		case 'updated_on':
			ret.sortBy = 'updatedOn';
			break;
		case 'type':
			ret.sortBy = 'docType';
			break;
		case 'content':
			ret.sortBy = 'remarkContent';
			break;
		case 'created_at':
		default:
			ret.sortBy = 'createdAt';
			break;
		}
		switch (param.get('sort_direction')) {
		case 'asc':
			ret.orderBy = 'ascending';
			break;
		case 'desc':
			ret.orderBy = 'descending';
			break;
		}
		switch (param.get('status')) {
		case 'enabled':
		case 'disabled':
			ret.status = param.get('status');
			break;
		case null:
		default:
			ret.status = 'all';
			break;
		}
		switch (param.get('type')) {
		case 'DP1':
		case 'SA1':
		case 'SA3':
			ret.docType = param.get('type');
			break;
		default:
			ret.docType = 'all';
			break;
		}
		return ret;
	}

	onChangeHandler(key, value) {
		let newState = {};
		newState[key] = value;
		this.setState(newState);
	}

	onSubmitHandler(e) {
		const { onChange } = this.props;
		const { orderBy, sortBy, status, docType } = this.state;
		e.preventDefault();
		let data = {
			sort_direction: orderBy === 'ascending' ? 'asc' : 'desc',
		};
		switch (sortBy) {
		case 'status':
			data.sort_column = 'status';
			break;
		case 'createdAt':
			data.sort_column = 'created_at';
			break;
		case 'updatedOn':
			data.sort_column = 'updated_on';
			break;
		case 'docType':
			data.sort_column = 'type';
			break;
		case 'remarkContent':
			data.sort_column = 'content';
			break;
		}
		switch (status) {
		case 'enabled':
		case 'disabled':
			data.status = status;
			break;
		case 'all':
		default:
			data.status = null;
			break;
		}
		switch (docType) {
		case 'all':
			data.type = null;
			break;
		default:
			data.type = docType;
			break;
		}
		onChange(data);
	}

	renderTab(label, tabs, value, onChange) {
		return (
			<div className={ classnames('uk-flex', styles.row) }>
				<div className={ classnames('uk-width-1-5', 'uk-text-primary', styles.label) }>
					{ label }
				</div>
				<div className={ classnames('uk-width-4-5', styles.value) }>
					<Tab
						tabs={ tabs }
						current={ value }
						onChange={ onChange }
						buttonClassName={ styles.tabButton }
					/>
				</div>
			</div>
		);
	}

	render() {
		const { isOpen, onToggle } = this.props;
		const { sortBy, orderBy, status, docType } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.advancedSearch }
				style={ this.modalStyle }
			>
				<form onSubmit={ this.onSubmitHandler } className={ styles.form }>
					<div className={ styles.filters }>
						{ this.renderTab(loc.sortBy, this.tabs.sortBy, sortBy, this.onChangeSortByHandler) }
						{ this.renderTab(loc.orderBy, this.tabs.orderBy, orderBy, this.onChangeOrderByHandler) }
						{ this.renderTab(loc.status, this.tabs.status, status, this.onChangeStatusHandler) }
						{ this.renderTab(loc.docType, this.tabs.docType, docType, this.onChangeDocTypeHandler) }
					</div>

					<div className={ styles.button }>
						<Button
							text={ loc.updateResults }
							theme="primary"
							type="submit"
							onClick={ this.onSubmitHandler }
						/>
					</div>
				</form>
			</Modal>
		);
	}
}

ModalAdvancedSearch.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	param: PropTypes.instanceOf(Map).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
		warehouseInfo: state.warehouseInfo,
		pmsFilterInfo: state.pmsFilterInfo,
		pmsFilterOptionsInfo: state.pmsFilterOptionsInfo,
	}),
	(dispatch) => ({
		getWarehouse: para => dispatch(getWarehouse(para)),
		getPmsFilters: para => dispatch(getPmsFilters(para)),
		getPmsFilterOptions: para => dispatch(getPmsFilterOptions(para)),
	})
)(ModalAdvancedSearch);