exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1qgpk {\n  height: calc(100% - 50px);\n  overflow: auto;\n}\n._1qgpk ._5LCeA {\n  height: calc(100% - 70px);\n  overflow: auto;\n}\n._1qgpk ._5LCeA .AVy8E {\n  padding-left: 20px;\n  padding-right: 20px;\n  height: 50px;\n  border-bottom: 0.5px solid #CBCBCB;\n}\n._1qgpk ._5LCeA .AVy8E .INh-Z {\n  line-height: 50px;\n}\n._1qgpk ._5LCeA .AVy8E ._35EOY {\n  padding-top: 7px;\n  padding-bottom: 7px;\n}\n._1qgpk ._5LCeA .AVy8E.tvL_i {\n  min-height: 90px;\n  height: auto;\n}\n._1qgpk ._5LCeA .AVy8E.tvL_i .INh-Z {\n  line-height: 53px;\n}\n._1qgpk ._5LCeA .AVy8E.tvL_i ._35EOY {\n  padding-top: 10px;\n}\n._1qgpk ._5LCeA .AVy8E.tvL_i ._35EOY ._2enKS {\n  margin-bottom: 10px;\n}\n._1qgpk ._5LCeA .fe6vD {\n  padding: 10px 20px;\n  border-bottom: 0.5px solid #CBCBCB;\n}\n._1qgpk ._5LCeA .fe6vD ._2bJtr {\n  padding-left: 10px;\n}\n._1qgpk ._5LCeA ._28qOj {\n  padding: 5px 15px;\n}\n._1qgpk ._3THel {\n  padding: 15px 20px;\n  text-align: center;\n}\n._1j7TH {\n  text-transform: capitalize;\n}\n", ""]);

// Exports
exports.locals = {
	"form": "_1qgpk",
	"filters": "_5LCeA",
	"row": "AVy8E",
	"label": "INh-Z",
	"value": "_35EOY",
	"row2x": "tvL_i",
	"tab": "_2enKS",
	"options": "fe6vD",
	"buttonAdd": "_2bJtr",
	"applied": "_28qOj",
	"button": "_3THel",
	"tabButton": "_1j7TH"
};