import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Map } from 'immutable';
import Select from 'react-select';
import Modal from 'Components/modal';
import Button from 'Components/button';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import { receiveApi } from 'Helpers/helpers';
import { getSalesRemarkTemplateDetails, createSalesRemarkTemplate, updateSalesRemarkTemplate, getSalesRemarkTemplateOptions } from 'Redux/actions';
import * as cons from 'Redux/constants';
import styles from './ModalSalesRemarkTemplate.less'; 

class ModalSalesRemarkTemplate extends PureComponent {
	constructor() {
		super();
		this.defaultSalesRemarkTemplate = new Map({
			status: {
				label: loc.Enabled,
				value: 1,
			},
			type: null,
			content: '',
		});
		this.state = {
			hasErrors: false,
			data: this.defaultSalesRemarkTemplate,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 400px)',
				right: 'calc(50% - 400px)',
				maxHeight: '600px',
				padding: 0,
			},
		};
		this.isLoading = this.isLoading.bind(this);
		this.getStatusOptions = this.getStatusOptions.bind(this);
		this.onChangeStatusHandler = this.onChangeStatusHandler.bind(this);
		this.onChangeTypeHandler = this.onChangeTypeHandler.bind(this);
		this.onChangeContentHandler = this.onChangeContentHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.renderRow = this.renderRow.bind(this);
	}

	componentDidMount() {
		const { current } = this.props;
		if (current) {
			this.props.getSalesRemarkTemplateDetails({ payload: { id: current } });
		}

		this.props.getSalesRemarkTemplateOptions();
	}

	componentDidUpdate(prevProps) {
		const current = this.props.current;
		const prevCurrent = prevProps.current;
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (current !== prevCurrent || (isOpen && isOpen !== prevIsOpen)) {
			if (current) {
				this.props.getSalesRemarkTemplateDetails({ payload: { id: current } });
			} else {
				this.setState({
					data: this.defaultSalesRemarkTemplate,
				});
			}
		}

		const salesRemarkTemplateDetailsInfo = this.props.salesRemarkTemplateDetailsInfo;
		const prevSalesRemarkTemplateDetailsInfo = prevProps.salesRemarkTemplateDetailsInfo;
		if (receiveApi(salesRemarkTemplateDetailsInfo, prevSalesRemarkTemplateDetailsInfo, cons.GET_SALES_REMARK_TEMPLATE_DETAILS)) {
			this.setState({
				data: this.defaultSalesRemarkTemplate
					.set(
						'status',
						salesRemarkTemplateDetailsInfo.data.status ? {
							label: loc[salesRemarkTemplateDetailsInfo.data.status.label],
							value: salesRemarkTemplateDetailsInfo.data.status.value,
						} : null
					)
					.set('type', salesRemarkTemplateDetailsInfo.data.type)
					.set('content', salesRemarkTemplateDetailsInfo.data.content)
				,
			});
		}

		if (salesRemarkTemplateDetailsInfo && salesRemarkTemplateDetailsInfo !== prevSalesRemarkTemplateDetailsInfo) {
			switch (salesRemarkTemplateDetailsInfo.type) {
			case cons.CREATE_SALES_REMARK_TEMPLATE.SUCCESS:
				UIkit.notification(loc.MSGRemarkTemplateCreated, { status: 'success' });
				this.props.onToggle();
				break;
			case cons.UPDATE_SALES_REMARK_TEMPLATE.SUCCESS:
				UIkit.notification(loc.MSGRemarkTemplateUpdated, { status: 'success' });
				this.props.onToggle();
				break;
			case cons.CREATE_SALES_REMARK_TEMPLATE.FAILURE:
				UIkit.notification(loc.MSGRemarkTemplateNotCreated, { status: 'danger' });
				break;
			case cons.UPDATE_SALES_REMARK_TEMPLATE.FAILURE:
				UIkit.notification(loc.MSGRemarkTemplateNotUpdated, { status: 'danger' });
				break;
			}
		}
	}

	isLoading() {
		const { salesRemarkTemplateDetailsInfo } = this.props;
		return salesRemarkTemplateDetailsInfo.isFetching;
	}

	getStatusOptions() {
		const { salesRemarkTemplateOptionsInfo } = this.props;
		const options = (salesRemarkTemplateOptionsInfo && salesRemarkTemplateOptionsInfo.data && salesRemarkTemplateOptionsInfo.data.options && salesRemarkTemplateOptionsInfo.data.options.status) ? salesRemarkTemplateOptionsInfo.data.options.status : null;
		if (!options) {
			return null;
		}
		return options.map((option) => ({
			label: loc[option.label],
			value: option.value,
		}));
	}

	onChangeStatusHandler(selectedOption) {
		const { data } = this.state;
		this.setState({
			data: data.set('status', selectedOption),
		});
	}

	onChangeTypeHandler(selectedOption) {
		const { data } = this.state;
		this.setState({
			data: data.set('type', selectedOption),
		});
	}

	onChangeContentHandler(e) {
		const { data } = this.state;
		this.setState({
			data: data.set('content', e.target.value),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { current } = this.props;
		let { data } = this.state;
		data = data.toJS();
		data.status = data.status ? data.status.value : null;
		data.type = data.type ? data.type.value : null;
		if (current) {
			data.id = current;
			this.props.updateSalesRemarkTemplate({
				payload: data,
			});
		} else {
			this.props.createSalesRemarkTemplate({
				payload: data,
			});
		}
	}

	renderRow(label, value) {
		return (
			<div className={ classnames('uk-flex', styles.row) }>
				<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label, 'uk-text-bold') }>
					{ loc[label] }
				</div>
				<div className={ classnames('uk-width-2-3', styles.value) }>
					{ value }
				</div>
			</div>
		);
	}

	render() {
		const { isOpen, onToggle, current, salesRemarkTemplateOptionsInfo } = this.props;
		const { data } = this.state;
		const isLoading = this.isLoading();
		const options = (salesRemarkTemplateOptionsInfo && salesRemarkTemplateOptionsInfo.data && salesRemarkTemplateOptionsInfo.data.options) ? salesRemarkTemplateOptionsInfo.data.options : null;
		return (
			<Modal
				title={ current ? loc.editRemarkTemplate : loc.addRemarkTemplate }
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<LoadingOverlay active={ isLoading } className={ styles.loadingOverlay }>
					<form onSubmit={ this.onSubmitHandler } className="uk-height-1-1">
						<div className={ styles.container }>
							{
								this.renderRow(
									'status',
									<Select
										isLoading={ isLoading }
										isDisabled={ isLoading }
										isClearable
										options={ this.getStatusOptions() }
										value={ data.get('status') }
										placeholder={ `${loc.select}...` }
										onChange={ this.onChangeStatusHandler }
									/>
								)
							}
							{
								this.renderRow(
									'docType',
									<Select
										isLoading={ isLoading }
										isDisabled={ isLoading }
										isClearable
										options={ options && options.type }
										value={ data.get('type') }
										placeholder={ `${loc.select}...` }
										onChange={ this.onChangeTypeHandler }
									/>
								)
							}
							{
								this.renderRow(
									'remarkContent',
									<textarea
										cols="30"
										rows="14"
										className={ classnames('uk-textarea', styles.textarea) }
										style={{ resize: 'none' }}
										placeholder={ loc.remarkContent }
										disabled={ isLoading }
										value={ data.get('content') }
										onChange={ this.onChangeContentHandler }
									/>
								)
							}
						</div>

						<div className={ styles.button }>
							<Button
								text={ loc.submit }
								theme="primary"
								type="submit"
							/>
							<Button
								text={ loc.reset }
								theme="default"
								type="reset"
								className="uk-margin-left"
								onClick={ this.onResetHandler }
							/>
						</div>
					</form>
				</LoadingOverlay>
			</Modal>
		);
	}
}

ModalSalesRemarkTemplate.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	current: PropTypes.number,
};

export default connect(
	(state) => ({
		salesRemarkTemplateDetailsInfo: state.salesRemarkTemplateDetailsInfo,
		salesRemarkTemplateOptionsInfo: state.salesRemarkTemplateOptionsInfo,
	}),
	(dispatch) => ({
		getSalesRemarkTemplateDetails: para => dispatch(getSalesRemarkTemplateDetails(para)),
		createSalesRemarkTemplate: para => dispatch(createSalesRemarkTemplate(para)),
		updateSalesRemarkTemplate: para => dispatch(updateSalesRemarkTemplate(para)),
		getSalesRemarkTemplateOptions: para => dispatch(getSalesRemarkTemplateOptions(para)),
	})
)(ModalSalesRemarkTemplate);