import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import loc from 'Components/languages';
import styles from './SalesRemarkTemplateListItem.less';

class SalesRemarkTemplateListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { salesRemarkTemplate, onSelect } = this.props;
		onSelect && onSelect(salesRemarkTemplate);
	}

	render() {
		const { salesRemarkTemplate } = this.props;
		const { onSelect } = this.props;
		if (!salesRemarkTemplate) {
			return null;
		}
		const createdAt = salesRemarkTemplate.created_at ? moment(salesRemarkTemplate.created_at) : null;
		const updatedOn = salesRemarkTemplate.updated_on ? moment(salesRemarkTemplate.updated_on) : null;
		let content = salesRemarkTemplate.content.split('\n');
		if (content.length > 3) {
			content = content.slice(0, 3).join('\n') + '...';
		} else {
			content = content.join('\n');
		}
		return (
			<li
				onClick={ this.onClickHandler }
				className={ classnames(styles.list, onSelect && styles.cursor) }
			>
				<div className="uk-card uk-card-body uk-card-small uk-position-relative">
					<div className="uk-flex uk-flex-between uk-child-width-1-1 uk-h5 uk-margin-remove-bottom">
						<div className={ classnames(styles.content, salesRemarkTemplate.status === 0 ? styles.disabled : 'uk-text-primary') }>
							{ content }
						</div>
					</div>
					<div className={ classnames('uk-flex uk-flex-between uk-text-small uk-text-capitalize', salesRemarkTemplate.status === 0 ? styles.disabled : 'uk-text-muted') }>
						<div className="uk-width-1-3">
							{ loc.createdAt }: { createdAt ? createdAt.format('YYYY-MM-DD HH:mm:ss') : '' }
						</div>
						<div className="uk-width-1-3">
							{ loc.updatedOn }: { updatedOn ? updatedOn.format('YYYY-MM-DD HH:mm:ss') : '' }
						</div>
						<div className="uk-width-1-3">
							{ loc.docType }: { salesRemarkTemplate.type }
						</div>
					</div>
					<div className={ classnames('uk-flex uk-flex-between uk-text-small uk-text-capitalize', salesRemarkTemplate.status === 0 ? styles.disabled : 'uk-text-muted') }>
						<div className="uk-width-1-3">
							{
								salesRemarkTemplate.created_by && (
									`${loc.createdBy}: ${salesRemarkTemplate.created_by.user_id} - ${salesRemarkTemplate.created_by.user_name}`
								)
							}
						</div>
						<div className="uk-width-1-3">
							{
								salesRemarkTemplate.updated_by && (
									`${loc.updatedBy}: ${salesRemarkTemplate.updated_by.user_id} - ${salesRemarkTemplate.updated_by.user_name}`
								)
							}
						</div>
						<div className="uk-width-1-3">
							{ loc.status }: { salesRemarkTemplate.status === 1 ? loc.Enabled : loc.Disabled }
						</div>
					</div>
				</div>
			</li>
		);
	}
}

SalesRemarkTemplateListItem.propTypes = {
	index: PropTypes.number.isRequired,
	salesRemarkTemplate: PropTypes.object,
	onSelect: PropTypes.func,
};

export default SalesRemarkTemplateListItem;