exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".QAN74 {\n  margin-top: 0 !important;\n  padding-top: 0 !important;\n}\n.QAN74._3jYbw {\n  cursor: pointer;\n}\n.QAN74 ._225YK {\n  color: #CBCBCB;\n  font-style: italic;\n}\n.QAN74 ._2GmQA {\n  white-space: pre-wrap;\n}\n.QAN74 ._2GmQA._225YK {\n  color: #A0A0A0;\n  font-style: italic;\n}\n", ""]);

// Exports
exports.locals = {
	"list": "QAN74",
	"cursor": "_3jYbw",
	"disabled": "_225YK",
	"content": "_2GmQA"
};