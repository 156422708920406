import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import Top from 'Components/top';
import LoadingOverlay from 'Components/loading-overlay';
import Button from 'Components/button';
import loc from 'Components/languages';
import Pagination from 'Components/pagination';
import { getSalesRemarkTemplates } from 'Redux/actions';
import * as cons from 'Redux/constants';
import SalesRemarkTemplateListItem from './components/SalesRemarkTemplateListItem.jsx';
import ModalSalesRemarkTemplate from './components/ModalSalesRemarkTemplate.jsx';
import ModalAdvancedSearch from './components/ModalAdvancedSearch.jsx';
import styles from './PageRemarkTemplate.less';

class PageRemarkTemplate extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
			size: 20,
			param: new Map({
				sort_column: 'created_at',
				sort_direction: 'desc',
				status: null,
				type: null,
			}),
			keyword: '',
			showModalSalesRemarkTemplate: false,
			showModalAdvancedSearch: false,
			current: null,
		};
		this.isLoading = this.isLoading.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onChangeParamHandler = this.onChangeParamHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onClickCreateHandler = this.onClickCreateHandler.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
		this.onToggleModalSalesRemarkTemplate = this.onToggleModalSalesRemarkTemplate.bind(this);
		this.onToggleModalAdvancedSearchHandler = this.onToggleModalAdvancedSearchHandler.bind(this);
		this.renderSalesRemarkTemplateListItem = this.renderSalesRemarkTemplateListItem.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		const salesRemarkTemplateDetailsInfo = this.props.salesRemarkTemplateDetailsInfo;
		const prevSalesRemarkTemplateDetailsInfo = prevProps.salesRemarkTemplateDetailsInfo;
		if (salesRemarkTemplateDetailsInfo && salesRemarkTemplateDetailsInfo !== prevSalesRemarkTemplateDetailsInfo) {
			switch (salesRemarkTemplateDetailsInfo.type) {
			case cons.CREATE_SALES_REMARK_TEMPLATE.SUCCESS:
			case cons.UPDATE_SALES_REMARK_TEMPLATE.SUCCESS:
				this.fetchData();
				break;
			}
		}
	}

	isLoading() {
		return this.props.salesRemarkTemplatesInfo.isFetching;
	}

	fetchData() {
		const { page, size, keyword } = this.state;
		let param = {
			payload: {
				...this.state.param.filter((v) => v).toJS(),
				page,
				size,
			},
		};
		if (keyword) {
			param.payload.keyword = keyword;
		}
		this.props.getSalesRemarkTemplates(param);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		});
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onChangeParamHandler(data) {
		const { param } = this.state;
		this.setState({
			param: param.merge(data),
			showModalAdvancedSearch: false,
		}, this.fetchData);
	}

	onSubmitHandler(e) {
		e.preventDefault();
		this.setState({
			page: 1,
		}, this.fetchData);
	}

	onClickCreateHandler() {
		this.setState({
			showModalSalesRemarkTemplate: true,
			current: null,
		});
	}

	onSelectHandler(salesRemarkTemplate) {
		this.setState({
			showModalSalesRemarkTemplate: true,
			current: salesRemarkTemplate.id,
		});
	}

	onToggleModalSalesRemarkTemplate() {
		this.setState({
			showModalSalesRemarkTemplate: false,
			current: null,
		});
	}

	onToggleModalAdvancedSearchHandler() {
		if (!this.isLoading()) {
			const { showModalAdvancedSearch } = this.state;
			this.setState({
				showModalAdvancedSearch: !showModalAdvancedSearch,
			});
		}
	}

	renderSalesRemarkTemplateListItem(salesRemarkTemplate, index) {
		return (
			<SalesRemarkTemplateListItem
				key={ `page-remark-template-sales-remark-template-list-item-${index}` }
				index={ index }
				salesRemarkTemplate={ salesRemarkTemplate }
				onSelect={ this.onSelectHandler }
			/>
		);
	}

	render() {
		const { salesRemarkTemplatesInfo } = this.props;
		const { keyword, showModalSalesRemarkTemplate, showModalAdvancedSearch, param, current } = this.state;
		const isLoading = this.isLoading();

		return (
			<Fragment>
				<div className="uk-flex uk-flex-column">
					<Top
						name="remarkTemplate"
					>
						<div className={ classnames('uk-margin-auto-left', styles.button) }>
							<Button
								text={ loc.addRemarkTemplate }
								theme="primary"
								disabled={ isLoading }
								onClick={ this.onClickCreateHandler }
							/>
						</div>
					</Top>

					<div className={ classnames('uk-flex', 'uk-padding-small', styles.searchBar) }>
						<div className="uk-flex uk-flex-1">
							<div className="uk-inline uk-width-expand">
								<div className={ styles.searchContainer }>
									<form onSubmit={ this.onSubmitHandler }>
										<div className={ styles.search }>
											<a onClick={ this.onSubmitHandler } className="uk-form-icon">
												<FontAwesomeIcon icon={ faSearch } className="uk-text-muted" />
											</a>
											<input
												type="text"
												value={ keyword }
												placeholder={ loc.remarkTemplateSearchHelpText }
												className="uk-input uk-border-rounded"
												onChange={ this.onChangeKeywordHandler }
												disabled={ isLoading }
											/>
										</div>
										<div className={ styles.btnSearch }>
											<button
												type="submit"
												disabled={ isLoading }
												className="uk-button uk-button-small uk-button-secondary"
											>
												{ loc.search }
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className={ classnames('uk-padding-small', 'uk-padding-remove-vertical', 'uk-text-middle', styles.button) } onClick={ this.onToggleModalAdvancedSearchHandler }>
							<FontAwesomeIcon icon={ faSlidersH } className={ classnames('fa-2x', 'uk-text-secondary', styles.icon) } />
						</div>
					</div>

					<LoadingOverlay active={ isLoading }>
						<div
							className={ classnames('uk-height-large', 'uk-overflow-auto', styles.list) }
							data-uk-height-viewport="offset-top: true; offset-bottom: 87px"
						>
							<ul className="uk-list uk-list-divider">
								{
									salesRemarkTemplatesInfo.data && salesRemarkTemplatesInfo.data.data && salesRemarkTemplatesInfo.data.data.map(this.renderSalesRemarkTemplateListItem)
								}
							</ul>
						</div>

						<Pagination
							data={ salesRemarkTemplatesInfo.data }
							onPageChange={ this.onChangePageHandler }
						>
							{ `${loc.totalResult}: ${salesRemarkTemplatesInfo.data.total}` }
						</Pagination>
					</LoadingOverlay>
				</div>

				<ModalSalesRemarkTemplate
					isOpen={ showModalSalesRemarkTemplate }
					onToggle={ this.onToggleModalSalesRemarkTemplate }
					current={ current }
				/>

				<ModalAdvancedSearch
					isOpen={ showModalAdvancedSearch }
					onToggle={ this.onToggleModalAdvancedSearchHandler }
					param={ param }
					onChange={ this.onChangeParamHandler }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		salesRemarkTemplatesInfo: state.salesRemarkTemplatesInfo,
		salesRemarkTemplateDetailsInfo: state.salesRemarkTemplateDetailsInfo,
	}),
	(dispatch) => ({
		getSalesRemarkTemplates: para => dispatch(getSalesRemarkTemplates(para)),
	})
)(PageRemarkTemplate);