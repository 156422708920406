exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ipeGp {\n  padding-top: 10px;\n}\n.AwJxr {\n  border-top: 1px solid #88733C;\n  background-color: #F6F4F0;\n  margin-left: -15px;\n  margin-right: -15px;\n}\n.AwJxr ._2t9Yg .UpBPP {\n  position: relative;\n  text-align: center;\n  padding: 0;\n  display: inline-block;\n  width: calc(100% - 115px);\n}\n.AwJxr ._2t9Yg .dhZhN {\n  display: inline-block;\n  position: relative;\n  width: 100px;\n  text-align: right;\n}\n.AwJxr ._2t9Yg .dhZhN button {\n  border-radius: 10px;\n}\n.AwJxr .ipeGp {\n  cursor: pointer;\n}\n.AwJxr .ipeGp ._1C4fW {\n  margin-top: 4px;\n}\n._1UUnO {\n  margin-left: -15px;\n  margin-right: -15px;\n}\n", ""]);

// Exports
exports.locals = {
	"button": "ipeGp",
	"searchBar": "AwJxr",
	"searchContainer": "_2t9Yg",
	"search": "UpBPP",
	"btnSearch": "dhZhN",
	"icon": "_1C4fW",
	"list": "_1UUnO"
};