import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageRemarkTemplate from 'Pages/remark-template';

const Report = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}`} component={ PageRemarkTemplate } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Report;